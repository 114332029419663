*{
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
body {
  font-size: 100%;
}

body, input, textarea, button, span, a {
  font: 500 1rem 1.4rem "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 1920px){
  :root{
    font-size: 16px;
  }
}

@media only screen and (max-width: 1366px){
  :root{
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px){
  :root{
    font-size: 12px;
  }
}

@media only screen and (max-width: 878px){
  body{
    background: #FFF;
  }
}
